// TODO stylize 404 page

import React from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/layout";

export default ({ data, pageContext }) => {
  const page = data.markdownRemark;
  const NotFoundInfo = styled.div`
    ${tw`my-16 text-center`}
  `;
  const NotFoundTitle = styled.h1``;
  const NotFoundMessage = styled.div``;

  return (
    <Layout
      pageContext={pageContext}
      title={page.frontmatter.title}
      lang={pageContext.lang}
    >
      <NotFoundInfo>
        <NotFoundTitle>{page.frontmatter.title}</NotFoundTitle>
        <NotFoundMessage
          dangerouslySetInnerHTML={{ __html: page.html }}
        ></NotFoundMessage>
      </NotFoundInfo>
    </Layout>
  );
};

export const query = graphql`
  query notFoundQuery($slug: String!) {
    markdownRemark(fields: { relativePath: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
